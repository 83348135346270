import { graphql } from 'gatsby'
import React from 'react'
import { BlogPostItemsQuery } from '../../types/graphql-types'
import { IfAwesomePagination } from '../../types/utils'
import HomeLayout from '../layouts/HomeLayout'
import HeadMeta from '../components/organisms/HeadMeta'
import ItemList from '../components/organisms/item-list/ItemList'
import Pager from '../components/molecules/pager/Pager'
import { routerName } from '../const/routerName'

interface IfProps {
  data: BlogPostItemsQuery
  location: Location
  pageContext: IfAwesomePagination
}

const BlogIndexTemplate = ({ data, pageContext }: IfProps) => {
  return (
    <HomeLayout>
      {' '}
      <HeadMeta ogType="website" /> <ItemList items={data.allWpPost} />
      <div className="pt-6 pb-6">
        <Pager
          items={pageContext}
          prefix={`/${routerName.home.archives}`}
          firstPagePrefix="/"
        />
      </div>
    </HomeLayout>
  )
}

export default BlogIndexTemplate

export const query = graphql`
  query BlogPostItems($skip: Int!, $limit: Int!) {
    allWpPost(
      skip: $skip
      limit: $limit
      filter: { status: { eq: "publish" } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          ...flagPostDefault
          excerpt
        }
      }
    }
  }
`
