import { Link } from 'gatsby'
import React from 'react'
import { useSiteMetaData } from '../../../hooks/useSiteMetaData'
import * as styles from './header-home.module.styl'

const HeaderHome = () => {
  const { defaultTitle } = useSiteMetaData()
  return (
    <header className={styles.header}>
      <div className={styles.headerContainer}>
        <h1 className={styles.headerHeadlineContainer}>
          <Link to="/" className={styles.headerHeadline}>
            {defaultTitle}
          </Link>
        </h1>
        <p className={styles.headerDescription}>
          Technology blog from Web Engineer
        </p>
      </div>
    </header>
  )
}

export default HeaderHome
