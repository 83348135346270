import React from 'react'
import '../css/global.styl'
import Footer from '../components/organisms/footer/Footer'
import HeaderHome from '../components/organisms/header/HeaderHome'

const HomeLayout: React.FC = ({ children }) => {
  return (
    <div>
      <HeaderHome />
      <div className="apps apps-main">
        <div className="apps-container">{children}</div>
      </div>
      <Footer />
    </div>
  )
}

export default HomeLayout
